$white-color: #F8F9F9;
$white-color1: #FDFDFF;

$text-black-color: #2B2B2B;
$text-Primary-color: #007791;

$bg-primary-color: #0F7C90;
$bg-primary-color1: #3d4977;
$bg-primary-bold: #1e293a;

$btn-primary-color: #EC5252;
