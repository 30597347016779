.videoContentMobile{  
   
    display: none;
    @media only screen and (max-width: 950px) {
        display: block;
      }  

} 
.videoContent{ 
     display: block;
    @media only screen and (max-width: 950px) {
        display: none;
      }
} 