.CourseCardContainer {
    position: relative;
    box-shadow: 0 10px 15px rgba(0,0,0,.06);
    border: 1px solid blue;
    .mediaImage {
        background-repeat: no-repeat;
        background-size: contain;
        background-color: #ffff;
        
    } 
    // transform: scale(1.05);
    // box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.06);

}  

// .progressBar{ 
//     margin: 20px;

// }

.container{
    background-color:#003399;
    padding: 5%;
    height: 140px;
}
// .prgInfo1{
//     color: white;
//     font-size: 14px;
// } 

.CourseCardContainer:hover{
    transform: scale(1.05);
     box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.06);
}