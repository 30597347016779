@import "./sass/index";
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // background-color: #F8F9F9;
  background: #fafafa;
}

* {
  font-family: "Lato", sans-serif !important;
  margin: 0;
  padding: 0;
}

