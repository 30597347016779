.courseVideoViewContainer {
  .courseInfoViewContainer {
    position: relative;
    margin: 10px auto;
    margin-bottom: 0;
    .react-player {
      position: relative;
      height: 400px !important;
      top: 0;
      left: 0;
    }
    .appBar {
      background-color: white;
      color: blue;
      width: 400px;
      margin-top: 50px;
    }

    .courseInfoPaginations {
      display: flex;
      justify-content: center;
      .iconContainer {
        margin: 10px 20px;
        .icons {
          cursor: pointer !important;
        }
        .disable {
          pointer-events: none;
          color: #d7dbdd;
        }
      }
    }
  }
}

.videoContentMobile {
  display: none;
  @media only screen and (max-width: 950px) {
    display: block;
  }
}
.videoContent {
  display: block;
  @media only screen and (max-width: 950px) {
    display: none;
  }
}

.player-wrapper {
  position: relative;
}

.player-overlay {
  position: absolute;
  width: 100%;
  height: 60px;
  left: 0;
  top: 0;
  z-index: 1;
}
