
.welcomeContainer {  
    max-width: 100%; 
    overflow-x: hidden; 
    .dimensions {
        @media screen and (max-width: 100%) {
            width: 250px;
        } 
    } 

    // .headingImageText {
    //     padding: 10px 0;
    //     font-size: 18px;
    //     font-weight: 600;
    //     // color: #666666;
    // }   
    // .headingImageTagLine {
    //     padding: 10px 0;
    //     font-size: 18px;
    //     flex-wrap: wrap;       
    //     font-weight: 400;      
    // }
    .headingText { 
        margin-top: 50px;
        padding: 10px 0;
        font-weight: 600; 
        color:#003399;
    }
    .headingTagLine {
        padding: 10px 0;
        font-size: 18px;
        flex-wrap: wrap;
        text-align: justify;
        font-weight: 400;  
        color:#003399;
    } 
    .headingTexts {
        padding: 10px 0;
        font-weight: 600; 
        
    }
    .headingTagLines {
        padding: 10px 0;
        font-size: 18px;
        flex-wrap: wrap;
        text-align: justify;
        font-weight: 400;   
        //color:azure;
        
    }   
    .headingLine {
        padding: 10px 0;
        font-size: 18px;
        flex-wrap: wrap;
        text-align: justify;
        font-weight: 400;   
        color:azure;
    }  
     .headingTexts1{
       text-align: center;
       padding: 10px 0;
       font-weight: 600; 
       color:azure;
     }
      
    .welcomeTopContainer {
        padding: 0px; 
        padding-bottom: 0px; 
        
        .headingText, .headingTagLine{ 
            //padding: 60px;
            width: 100%;
            text-align: center;  
            
           
        } 
        .startImageContainer{
          //background-size: contain; 
          // width: 100%;
           //height: 500px; 
          //min-width: 100vh; 
          position: relative;
          margin-top: 15px;
        }   
        .startImage{ 
            width: 100%; 
            height: 580px; 
            position: relative;
        }
          

         .textImage{
            position: absolute; 
            align-items: center;
            top: 27%;
            left: 11%;
            right:11%;   
            max-width:70%; 
            height:52%;
            border: 3px solid #e4e4e4; 
            background-color: #003399; 

            .headingImageText{ 
                
                font-size: 35px;
                font-weight: 400;
                //padding: 5px;  
                color:#e4e4e4;
                margin:11px 0 10px 0px;
                padding:0 0 0 10px;
                border-bottom: 3px solid #e4e4e4;

                @media only screen and (max-width: 600px) { 
                    font-weight: 700;
                    font-size: 19px;
                    //padding: 10px 75px;
                }
            }
            .headingImageTagLine {
                text-align: center; 
                font-size: 18px;
                //text-transform: uppercase; 
                //padding-top: px; 
                margin-top: 30px;
                padding: 8px ; 
                margin-left: 50px; 
                margin-right: 30px;
                //letter-spacing: 0px;
                color:#e4e4e4;
                font-weight:400; 
                flex-wrap: wrap;
                text-align: justify;  
                @media only screen and (max-width: 700px) { 
                    margin-top: auto;
                    font-size: 10px;  
                    padding: 40px 0;
                    //max-width: ;
                }
            }

            
          }   
         
        
       .headingTagLine {
           padding: 0; 
           color:#003399;
       }
        .buttonHeadingStartContainer {
            padding: 20px;
            .buttonHeadingStart {
                text-transform: capitalize;
                font-size: 16px;
                font-weight: 600;
            }
            .startArrow {
                font-size: 16px;
                margin-left: 10px;
            } 
        }
    }
    .welcomeStartImageContainer {
        padding: 20px;
        text-align: center;
        .welcomeStartImage {
            width: 400px;
        }
        .dimensions {
            @media screen and (max-width: 600px) {
                width: 250px;
            }
        }
    }
    .welcomeNewUser, .welcomeTestimonials {
        margin: 20px 0;   
         
        .headingText, .headingTagLine {
            width: 100%;
            text-align:center; 
            color:#e4e4e4;
            
        }     
         
    }    

    
    // .welcomeScenarioBasedLearning {
    //     padding: 50px; 
    //    .headingText{
    //        margin-right: 80px;
    //    }
    // }
    
    // .welcomeScenarioBasedButton { 
    //     display: flex;
    //     justify-content: center;
    //     align-items: center;
    //     .scenarioReadMore {
    //         text-transform: capitalize;
    //         font-size: 16px;
    //         font-weight:600; 
    //     } 
    // }
}