.forgotPasswordContainer, .resetPasswordContainer { 
    .forgotPasswodInnerContainer, .resetPasswodInnerContainer {
        display: flex;
        height: calc(100vh - 64px);
        justify-content: center;
        align-items: center;  
        margin-top: 5px;   
        //background-image: url("../../images/image3.png"); 
        .forgotPasswodCardContent, .resetPasswodCardContent {
            //padding: 50px;  
              margin: 50px;
           // width: 0%; 
           
        } 
        .card { 
            width: 50% ;  
            background-image: url("../../images/image3.png"); 
            @media screen and (max-width: 600px) { 
              width: 80%;
            } 
        }
        .headingText {
            font-size: 30px;
            text-align: center; 
            color:#003399; 
            @media screen and (max-width: 600px) { 
              font-size: 15px;
              } 
        }
        .submitButton {
            //margin: 40px 0;
            margin-bottom: 0; 
            //padding-left: 200px; 
            .formSubmit {  
                width: 100%;
                font-weight: 600; 
                text-transform: capitalize;
                letter-spacing: 1px;
                background:#003399;
            }
        }
        .alreadyAccountContainer {
            display: flex;
            justify-content: center;
           margin: 10px; 
           //margin-top: 70px;
            .alreadyAccount {
                padding: 0 5px; 
                color: #003399;
                cursor: pointer;
                text-decoration: none;
                font-weight: 600;
            }
        } 

        .login{
           font-family: 'Lato', sans-serif !important;
           background-image: url("../../images/image3.png"); 
        }  
        .paragraph{
            color: #003399; 
            padding-bottom: 30px;
        } 
        .formContainer{
            margin-top: 20px;
        }
    }
} 