.imageContentMobile{  
    display: none;
    @media only screen and (max-width: 950px) {
        display: block;
      }  

}  

.image{
     display: block;
    @media only screen and (max-width: 950px) {
        display: none;
      }
}