// .headerContainer {
//   background-color: $white-color !important;
//   color: $text-black-color !important;
//   .headerButton {
//     font-weight: 700;
//     text-transform: capitalize;
//     letter-spacing: 0.1rem;
//     font-size: 16px;
//     color: $white-color;
//     border-radius: 20px;
//     max-width: "50%";
//     background-color: #008dd2;
//     //  margin-right: 110px;
//   }
// }

.headerContainer {
  background-color: $white-color !important;
  color: $text-black-color !important;
  box-shadow: none !important;
  .headerContent {
    display: flex;
    align-items: center;
    .headerButton {
      font-weight: 600;
      text-transform: capitalize;
      letter-spacing: 0.1rem;
      font-size: 19px;
      border-radius: 22px;
      background-color: #003399;
      color: $white-color;
    }
  }
}

//------------------------------------------------------------- //

.menuItems {
  position: relative;
}
.menuItems::after {
  position: absolute;
  content: "";
  background-color: white;
  height: 3px;
  width: 0;
  bottom: -12px;
  transition: 0.3s;
  left: 4%;
  right: 4%;
}
.menuItems:hover::after {
  width: 100%;
  background-color: whitesmoke;
  @media screen and (max-width: 960px) {
    width: 0%;
  }
}

.menuItems1 {
  position: relative;
}
.menuItems1::after {
  position: absolute;
  content: "";
  background-color: whitesmoke;
  height: 3px;
  width: 100%;
  bottom: -12px;
  transition: 0.3s;
  left: 4%;
  right: 4%;
  @media screen and (max-width: 960px) {
    width: 0%;
  }
}
// .navBar{
//     background-color: #048fd3;
//     height: 90px;
//     display: flex;
//     // flex-direction: column;
//     justify-content: center;
//     align-items: center;
//     font-size: 18px;

// }

.navBar {
  background-color: #5e72e4;
  height: 90px;
  display: flex;
  // justify-content: space-around;
  // align-items: center;
  flex-direction: column;
  font-size: 18px;
  padding-left: 3%;
  padding-top: 5px;
}

.navBarTitle {
  color: white;
  justify-self: start;
  margin-left: 25px;
}

.navMenu {
  // display: grid;
  // grid-template-columns: repeat(5,auto);
  // grid-gap: 10px;
  // list-style: none;
  // text-align: center;
  // width: 100%;
  // justify-content: end;
  // margin-right: 20px;
  padding-top: 4px;
  margin-top: 20px;
  display: flex;
  justify-content: flex-start;
  list-style: none;
  width: 75%;
  margin-left: 12px;
  font-size: 25px;
  // padding-left: 2%;
}
.nav-links {
  color: white;
  text-decoration: none;
  padding: 0.5rem 1rem;
  font-size: 20px;
  display: inline;
}

.menu-icon {
  display: none;
}
.searchBar {
  //flex-direction: column;
  // position: relative;
  align-items: flex-end;
  display: flex;
  flex-direction: column;
}
@media screen and (max-width: 960px) {
  .navBar {
    position: relative;
  }
  .navMenu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 300px;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }
  .navMenu.active {
    //background-color: #048fd3;
    left: 0%;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }
  .nav-links {
    text-align: center;
    padding: 2%;
    width: 100%;
    display: table;
    font-size: 18px;
  }
  .nav-links:hover {
    background-color: #003399;
    border-radius: 0;
    left: -100%;
    //text-Decoration: none;
  }
  .navBarTitle {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(10%, 50%);
  }
  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    cursor: pointer;
  }
}
