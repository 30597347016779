// .signInContainer {
//     .signInInnerContainer {
//         display: flex;
//         height: calc(100vh - 64px);
//         justify-content: center;
//         align-items: center;
//         .signInCardContent {
//             padding-bottom: 16px;
//         }
//         .headingText {
//             font-weight: 600;
//             text-align: center;
//         }
//         .submitButton {
//             margin: 10px 0;
//             margin-bottom: 0;
//             .formSubmit {
//                 width: 100%;
//                 font-weight: 600;
//                 text-transform: capitalize;
//                 letter-spacing: 1px;
//                 //background: $btn-primary-color; 
//                 background: "#F8F9F9";
//             }
//         }
//         .forgotPasswordContainer, .alreadyAccountContainer {
//             display: flex;
//             justify-content: center;
//             margin: 10px;
//             .forgotPassword, .alreadyAccount {
//                 padding: 0 5px;
//                 //color: $text-Primary-color; 
//                 color: red;
//                 cursor: pointer;
//                 text-decoration: none;
//             }
//             .alreadyAccount {
//                 font-weight: 600;
//             }
//         }
//     }
// }  




.login{
    font-family: 'Lato', sans-serif !important;
    
       background-image: url("../../images/image3.png");
       background-size: cover;
       position: relative;
       width:100%;
       height: 100vh;
       /* resize:both; */
       //overflow: scroll;
       background-position: center; /* Center the image */
       background-repeat: no-repeat; /* Do not repeat the image */
       
       
   }
   
   .SigninIC{
       padding-left: 22%;
       padding-top: 10%;
       padding-right:20%
   }
   
   .LeftBox
   {
       background-color: #003399;
       font-family: "Verdana, Arial, Helvetica", sans-serif!important;
       font-size: 30px;
       color:white;
       height: 385px;
       border-top-left-radius: 8px;
       border-bottom-left-radius: 8px;
       width: 990px;
       grid-auto-flow: column;
        grid-auto-columns: minmax(260px, 1fr);
   }
   
   .RightBox
   {
       color: #003399;
       font-family: 'Lato', sans-serif !important;
       background-color:white;
       border-top-right-radius: 8px;
       border-bottom-right-radius: 8px;
       height: 385px;
       grid-auto-flow: column;
        grid-auto-columns: minmax(260px, 1fr);
   }
   
   .login-form-data1{
       width:350px !important;   
   }
   
   .login-button{
       border-radius: 12px;
       border:none;
       width:100px;
   }
   
   
   .home-button{
       
       border-radius: 12px;
       border:none;
       width:100px;
       background-color: #fff;
       border: none;
       box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
       transition: all 0.3s ease 0s;
       cursor: pointer;
   }
   
   /* grid-auto-flow: column;
   grid-auto-columns: minmax(260px, 1fr); */
   .eye-icon
   {
       background-color: #fff;
       color:#003399
    ;
       border:none;
   }
   
   .eye-box
   {
       background-color: #fff;
   }
   
   .mail-icon
   {
       background-color: #fff;
       color:#003399
    ;
       border:none;
   }
   
   .mail-box
   {
       background-color: #fff;
   }
   
   .RightHeader1{
   padding-top: 15%;
   padding-bottom:8%;
   font-size: 30px;
   font-family: Verdana, Arial, Helvetica, sans-serif;
   }
   
   .LeftBoxHead
   {
       padding-left: 14% ;
       padding-top: 15%;
       font-size: 28px;
       font-family:Verdana, Arial, Helvetica, sans-serif ;
   }
   
   .LeftBoxSubHead-1
   {
   padding-top: 8%;
   font-size: 19px; 
   padding-bottom: 5px; 
   padding-left: 10px;
   }
   
   .LeftBoxSubHead-2
   { 
    padding-top: 5%;
   padding-bottom: 20%;
   font-size: 18px; 
   padding-left: 10px;
   }
   
   .ForgotpassStyle{
       position: relative;
       left: 110px;
   }
   
   
   @media screen and (max-width: 600px)
   {
   
       .SigninIC{
           padding-left: 4%;
           /* padding-top: 10%; */
           padding-right:4% 
       }
       
       .LeftBoxSubHead-1
   {
   padding-top: 6%;
   font-size: 15px;
   }
   
   .LeftBoxSubHead-2
   {
   padding-bottom:0%;
   font-size: 15px;
   }
   
       .LeftBox
   {
       background-color: #003399;
       font-family: "Verdana, Arial, Helvetica", sans-serif!important;
       font-size: 20px;
       color:white;
       border-top-right-radius: 8px;
       border-bottom-right-radius: 8px;
       border-top-left-radius: 8px;
       border-bottom-left-radius: 8px;
       height:280px;
       
       
   }
   
   .RightBox
   {
       color: #003399;
       font-family: 'Lato', sans-serif !important;
       background-color:white;
       border-top-right-radius: 8px;
       border-bottom-right-radius: 8px;
       border-top-left-radius: 8px;
       border-bottom-left-radius: 8px;
       height: 328px;
       
   }
   
   .login{
       font-family: 'Lato', sans-serif !important;
       
         //background-image: url("../../images/image3.png");
           background-size:cover;
          /* position: relative;  */
          /* width:100%*/
          height: 100vh; 
          max-width: 100%;
          /* height: auto; */
           background-position: center; 
          background-repeat: no-repeat;    
          
      }
   
      .login-form-data1{
       width:225px !important;   
   }
   .ForgotpassStyle{
       position: relative;
       left: 0px;
   }
   
   .LeftBoxHead
   {
       padding-left: 3% ;
       padding-top: 10%;
       font-size: 20px;
       font-family:Verdana, Arial, Helvetica, sans-serif ;
   }
   
   .RightHeader1   {
       padding-top: 10%;
       padding-bottom:0%;
       /* font-size: 18px; */
       font-family: Verdana, Arial, Helvetica, sans-serif;
       }
   
       .login-button{
           border-radius: 12px;
           border:none;
           width:100px;
       }
       
       
       .home-button{
           
           border-radius: 12px;
           border:none;
           width:100px;
           background-color: #fff;
           border: none;
           box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
           transition: all 0.3s ease 0s;
           cursor: pointer;
       } 

       .forgotPasswordContainer, .alreadyAccountContainer {
                    display: flex;
                    justify-content: center;
                    margin: 10px;
                    .forgotPassword, .alreadyAccount {
                        padding: 0 5px;
                        //color: $text-Primary-color; 
                        color: red;
                        cursor: pointer;
                        text-decoration: none;
                    }
                    .alreadyAccount {
                        font-weight: 600;
                    }
                }
       
   }