.dashboardcardcontainer{
    position: relative;
    height: 220px;
    width: 320px;
    overflow: hidden;
    background-color:#003399 ; 
    color: white; 
    border-radius: 40px; 
    box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
  }
  
  .dashboardcardcontainer:before { 
    content: '';
    position: absolute;
    left: 40%;
    width: 100%; 
    height: 200%; 
    background-color: rgb(255, 255, 255); /* fallback */
    //background-color: rgba(255, 255, 255, 0.5);
    top: 0;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    transform: rotate(55deg); 
    color:black;
  } 
  .textbottom{
    position: relative;
    //bottom: 0;
    //right: 0; 
    color:black; 
    text-align:center;
    padding-top:1px; 
    padding-left: 200px;  
    //font-size: 100%;
    //margin-bottom: 20%;

  }